interface Props {
	slice: any
}

export default function ThreeWidgets({ slice }: Props) {
	const { items } = slice

	return (
		<section className="relative pb-16 three-widgets lg:pb-24">
			{/* Background color */}
			<div
				className="absolute inset-x-0 bottom-0 bg-gray-150 lg:top-0 top-14 -z-20 lg:-translate-y-56"
				aria-hidden="true"
			/>

			<div className="container flex max-lg:items-center max-lg:flex-col max-lg:gap-y-10 lg:gap-x-16 xl:gap-x-20 lg:justify-between">
				{items.map((widget: any, i: any) => {
					let cardBackgroundColor
					let cardSubtitleColor

					if (i === 0) {
						cardBackgroundColor = 'bg-bbx-crystal'
						cardSubtitleColor = 'text-bbx-style'
					} else if (i === 1) {
						cardBackgroundColor = 'bg-bbx-glow'
						cardSubtitleColor = 'text-bbx-pearl'
					} else {
						cardBackgroundColor = 'bg-bbx-style'
						cardSubtitleColor = 'text-bbx-pearl'
					}
					return (
						<div
							className="bg-white shadow-bbx-xl max-w-[320px]"
							// eslint-disable-next-line react/no-array-index-key
							key={i}
						>
							{/* Card title */}
							{widget?.section_card_title?.[0]?.text && (
								<div className={`py-9 px-10 relative ${cardBackgroundColor}`}>
									<h3 className={`text-xl font-medium md:text-2xl ${cardSubtitleColor}`}>
										{widget.section_card_title[0].text}
									</h3>

									{/* Right side shadow */}
									<div className="absolute inset-y-0 right-0 w-5 bg-bbx-style/10" aria-hidden="true" />
								</div>
							)}

							<div className="px-8 pt-12 mt-auto pb-14 lg:pb-10 lg:pl-10 lg:pt-9">
								{/* Card section subtitle */}
								{widget.section_box_subtitle?.[0]?.text && (
									<h4 className={`mb-1 ${cardSubtitleColor}`}>
										{widget.section_box_subtitle[0].text}
									</h4>
								)}

								{/* Card section title */}
								{widget.section_box_title?.[0]?.text && (
									<h3 className="text-2xl font-medium md:text-3xl">
										{widget.section_box_title[0].text}
									</h3>
								)}

								{/* Card section description */}
								{widget.section_box_description?.[0] && (
									<p className="mt-4 font-light max-md:text-sm">
										{widget.section_box_description[0].text}
									</p>
								)}
							</div>
						</div>
					)
				})}
			</div>
		</section>
	)
}
